<template>
  <div>
    <table class="table bg-white">

      <tr v-for="(room, index) of rooms"
          :key="index">
        <td><h6 class="mb-0">{{ room.title }}
          <span v-if="room.online_medium=='zoom' || room.online_medium=='Zoom'"><img
              src="https://img.icons8.com/color/20/000000/zoom.png"/></span>
          <span v-if="room.online_medium=='Zoom Link'"><img
              src="https://img.icons8.com/color/20/000000/zoom.png"/></span>
          <span v-if=" room.online_medium=='Meet' || room.online_medium=='meet'"><img
              src="https://img.icons8.com/color/48/000000/google-meet.png"/></span>
          <span v-if="room.online_medium=='Teams' || room.online_medium=='teams'"><img
              src="https://img.icons8.com/color/48/000000/microsoft-teams.png"/></span>
        </h6>
          {{ room.start_from }} - {{ room.end_to }}<br/>
          {{ room.formatted_date }}
        </td>
        <td>
          {{ room.linked_classes }} <br/>
          Year 1
          BIT - Batch 2020

        </td>
        <td>
          <a :href="room.meeting_link" target="_blank" class="btn btn-success" variant="primary">Completed</a>
        </td>
        <td>
          <a v-if="room.class_status === 'completed'" href="#" @click="viewOnlineAttendance(room)">View
            Attendees[{{ room.total_attendee }}]</a>
        </td>
        <td>
          <a v-if="room.video_play_url" :href="room.video_play_url" target="_blank" class="btn btn-primary">View
            Recording</a> <br/><br/>
          <code v-if="room.video_password"
                style="color:white; background: green; font-family: 'Poppins'; font-size:13px; margin-top: 2px;">Password:
            {{ room.video_password }}</code> <small><a href="#">Copy</a></small><br/>
        </td>
      </tr>
    </table>
    <online-class-attendance v-if="room_id && attendance_dialog"
                             ref="online-class-attendance"
                             :attendance_dialog="attendance_dialog"
                             :room="room"
                             :room_id="room_id"
                             :course_id="course_id"
                             @close_dialog="closeDialog"
    >
    </online-class-attendance>
  </div>
</template>

<script>
import OnlineClassAttendance from "@/view/component/online-class-room/OnlineClassAttendance";
import OnlineClassRoomMixin from "@/mixins/OnlineClassRoomMixin";

export default {
  name: "RecordedOnlineClassRoomList",
  mixins: [OnlineClassRoomMixin],
  props: ['rooms'],
  components: {
    OnlineClassAttendance
  }
}
</script>

<style scoped>

</style>