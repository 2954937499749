<template>
  <v-app>
    <div class="row">
      <div class="col-12">
         <div class="card ">
           <div class="card-header">
             <div class="card-title pt-3 px-3 d-flex justify-content-between">
               <div class="breadcrumb-left">
                 <h4>
                   Manage online classroom
                 </h4>
                 <div class="breadcrumb-sub-header">
                   <router-link to="/dashboard">Dashboard </router-link>\   Online ClassRoom
                 </div>
               </div>
               <div class="breadcrumb-right">
                 <v-btn v-if="checkIsAccessible('online-class-room', 'create')"
                        @click="addRooms()"
                        class="mt-4 btn btn-primary  mr-1"
                        dark>
                   <i class="fa fa-plus"></i>
                   Add online classroom
                 </v-btn>

               </div>
             </div>
           </div>
           <div class="card-body">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="3">
                    <v-text-field
                        outlined
                        dense clearable
                        label="Title"  v-on:keyup.enter="searchResult()"
                        v-model="search.title"
                    >

                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dates"
                            label="Dates"

                            readonly clearable
                            v-bind="attrs"  prepend-icon=""
                            prepend-inner-icon="mdi-calendar"
                            v-on="on"  v-on:keyup.enter="searchResult()"
                            outlined
                            dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="dates"
                          range
                      ></v-date-picker>
                    </v-menu>

                  </v-col>

                  <v-col cols="2">
                    <v-select
                        :items="programs"
                        item-value="id"
                        item-text="title"
                        outlined
                        dense  v-on:keyup.enter="searchResult()"
                        label="Program"
                        v-model="search.program_id"
                        @change="getGrades(search.program_id)"
                    >

                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                        :items="grades"
                        item-value="id"
                        item-text="title"
                        outlined  v-on:keyup.enter="searchResult()"
                        dense
                        label="Grade"
                        v-model="search.grade_id"
                    >

                    </v-select>
                  </v-col>
                  <v-col cols="2" md="3" class="text-right">
                    <v-btn
                        @click="searchResult()"
                        class="btn btn-primary btn-search w-35"

                    >
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>

              <div class="">
                <div class="row">
                  <div class="col-12">
                    <template>
                      <v-card>
                        <v-tabs vertical>
                          <v-tab>
                            <v-icon left>
                              mdi-access-point-network
                            </v-icon>
                            Online classes
                          </v-tab>
                          <v-tab>
                            <v-icon left>
                              mdi-record-circle
                            </v-icon>
                            Recorded classes
                          </v-tab>

                          <v-tab-item class="p-4 bg-white">
                            <div class="" v-if="days_rooms && days_rooms.length>0">
                              <online-class-room-list :rooms="days_rooms" @refresh_list="getRooms"></online-class-room-list>
                              <div class="col-12" v-if="total>perPage">
                                <b-pagination
                                    @input="getRooms"
                                    v-model="page"
                                    :total-rows="total"
                                    :per-page="perPage"
                                    first-number
                                    last-number
                                ></b-pagination>
                                <!--  -->
                              </div>
                            </div>
                            <div class="row" v-else>
                              <div class="col-12 text-center">
                                <h6>No class room found</h6>
                              </div>
                            </div>
                          </v-tab-item>
                          <v-tab-item class="p-4 bg-white">
                            <div class="" v-if="recorded_rooms && recorded_rooms.length>0">
                              <recorded-online-class-room-list :rooms="recorded_rooms"></recorded-online-class-room-list>
                              <div class="col-12" v-if="recorded_total>recorded_perPage">
                                <b-pagination
                                    @input="getRecordedRooms"
                                    v-model="recorded_page"
                                    :total-rows="recorded_total"
                                    :per-page="recorded_perPage"
                                    first-number
                                    last-number
                                ></b-pagination>
                              </div>
                            </div>
                            <div class="row" v-else>
                              <div class="col-12 text-center">
                                <h6>No class room found</h6>
                              </div>
                            </div>
                          </v-tab-item>
                        </v-tabs>
                      </v-card>
                    </template>
                    <!--                  <v-expansion-panels focusable>-->
                    <!--                    <v-expansion-panel-->
                    <!--                        v-for="(room, index) of days_rooms"-->
                    <!--                        :key="index"-->
                    <!--                    >-->
                    <!--                      <v-expansion-panel-header>-->
                    <!--                        {{ `${room.title} ` }}-->
                    <!--                        <span v-if="room.online_medium=='zoom' || room.online_medium=='Zoom'"><img-->
                    <!--                            src="https://img.icons8.com/color/20/000000/zoom.png"/></span>-->
                    <!--                        <span v-if="room.online_medium=='Zoom Link'"><img-->
                    <!--                            src="https://img.icons8.com/color/20/000000/zoom.png"/></span>-->
                    <!--                        <span v-if=" room.online_medium=='Meet' || room.online_medium=='meet'"><img-->
                    <!--                            src="https://img.icons8.com/color/48/000000/google-meet.png"/></span>-->
                    <!--                        <span v-if="room.online_medium=='Teams' || room.online_medium=='teams'"><img-->
                    <!--                            src="https://img.icons8.com/color/48/000000/microsoft-teams.png"/></span>-->
                    <!--                      </v-expansion-panel-header>-->
                    <!--                      <v-expansion-panel-content>-->
                    <!--                        <p v-if="room.linked_subjects" class="mt-2"><b> {{-->
                    <!--                             `${room.linked_subjects} - ${room.linked_teacher} [${room.linked_classes}]`-->
                    <!--                          }}</b></p>-->
                    <!--                        <v-chip x-small class="text-uppercase text-sm green text-white mb-2"-->
                    <!--                                v-if="room.is_active">Active-->
                    <!--                        </v-chip>-->
                    <!--                        <v-chip x-small class="text-uppercase text-sm green text-white mb-2"-->
                    <!--                                v-else>Inactive-->
                    <!--                        </v-chip>-->
                    <!--                        <p>{{ room.duration }} mins Class | {{ room.start_from }} - {{-->
                    <!--                            room.end_to-->
                    <!--                          }} | {{ room.formatted_date }}</p>-->
                    <!--                        <p><a href="#" @click="editRooms(room.online_class_room_id)">Edit</a> | <a href="#"-->
                    <!--                                                                                                   @click="deleteRooms(room.online_class_room_id)">Delete</a>-->
                    <!--                        </p>-->
                    <!--                        <a :href="room.meeting_link" target="_blank" class="btn btn-primary"-->
                    <!--                           variant="primary"-->
                    <!--                           v-if="room.meeting_link">Start Class</a>-->
                    <!--                        <a :href="room.meeting_link" target="_blank" class="btn btn-secondary"-->
                    <!--                           variant="primary" v-else>No-->
                    <!--                          Link to Start</a>-->
                    <!--                        <a href="#" class="btn btn-secondary ml-2" v-if="room.meeting_link"-->
                    <!--                           v-clipboard="room.meeting_link"-->
                    <!--                           @success="onCopy"-->
                    <!--                           @error="onError"> Copy Invitation Link</a>-->
                    <!--                        <a href="#" class="btn btn-secondary ml-2" v-else> No Link to copy</a>-->
                    <!--                      </v-expansion-panel-content>-->
                    <!--                    </v-expansion-panel>-->
                    <!--                  </v-expansion-panels>-->

                  </div>
                </div>
              </div>
             <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <!--begin: Wizard Form-->



            <!--              <div class="col-6" v-if="rooms && rooms.length>0">-->
            <!--                <v-sheet height="64">-->
            <!--                  <v-toolbar-->
            <!--                      flat-->
            <!--                  >-->
            <!--                    <v-btn-->
            <!--                        outlined-->
            <!--                        class="mr-4"-->
            <!--                        color="grey darken-2"-->
            <!--                        @click="setToday"-->
            <!--                    >-->
            <!--                      Today-->
            <!--                    </v-btn>-->
            <!--                    <v-btn-->
            <!--                        fab-->
            <!--                        text-->
            <!--                        small-->
            <!--                        color="grey darken-2"-->
            <!--                        @click="prev"-->
            <!--                    >-->
            <!--                      <v-icon small>-->
            <!--                        mdi-chevron-left-->
            <!--                      </v-icon>-->
            <!--                    </v-btn>-->
            <!--                    <v-btn-->
            <!--                        fab-->
            <!--                        text-->
            <!--                        small-->
            <!--                        color="grey darken-2"-->
            <!--                        @click="next"-->
            <!--                    >-->
            <!--                      <v-icon small>-->
            <!--                        mdi-chevron-right-->
            <!--                      </v-icon>-->
            <!--                    </v-btn>-->
            <!--                    <v-toolbar-title v-if="$refs.calendar">-->
            <!--                      {{ $refs.calendar.title }}-->
            <!--                    </v-toolbar-title>-->
            <!--                    <v-spacer></v-spacer>-->
            <!--                    <v-menu-->
            <!--                        bottom-->
            <!--                        right-->
            <!--                    >-->
            <!--                      <template v-slot:activator="{ on, attrs }">-->
            <!--                        <v-btn-->
            <!--                            outlined-->
            <!--                            color="grey darken-2"-->
            <!--                            v-bind="attrs"-->
            <!--                            v-on="on"-->
            <!--                        >-->
            <!--                          <span>{{ typeToLabel[ctype] }}</span>-->
            <!--                          <v-icon right>-->
            <!--                            mdi-menu-down-->
            <!--                          </v-icon>-->
            <!--                        </v-btn>-->
            <!--                      </template>-->
            <!--                      <v-list>-->
            <!--                        <v-list-item @click="ctype = 'day'">-->
            <!--                          <v-list-item-title>Day</v-list-item-title>-->
            <!--                        </v-list-item>-->
            <!--                        <v-list-item @click="ctype = 'week'">-->
            <!--                          <v-list-item-title>Week</v-list-item-title>-->
            <!--                        </v-list-item>-->
            <!--                        <v-list-item @click="ctype = 'month'">-->
            <!--                          <v-list-item-title>Month</v-list-item-title>-->
            <!--                        </v-list-item>-->
            <!--                        <v-list-item @click="ctype = '4day'">-->
            <!--                          <v-list-item-title>4 days</v-list-item-title>-->
            <!--                        </v-list-item>-->
            <!--                      </v-list>-->
            <!--                    </v-menu>-->
            <!--                  </v-toolbar>-->
            <!--                </v-sheet>-->
            <!--                <v-sheet height="400">-->
            <!--                  &lt;!&ndash;                  <v-calendar&ndash;&gt;-->
            <!--                  &lt;!&ndash;                      ref="calendar"&ndash;&gt;-->
            <!--                  &lt;!&ndash;                      :attributes="items"&ndash;&gt;-->
            <!--                  &lt;!&ndash;                      @click:event="showEvent"&ndash;&gt;-->
            <!--                  &lt;!&ndash;                      v-model="focus"&ndash;&gt;-->
            <!--                  &lt;!&ndash;                      :type="ctype"&ndash;&gt;-->
            <!--                  &lt;!&ndash;                      :event-overlap-mode="mode"&ndash;&gt;-->
            <!--                  &lt;!&ndash;                      event-text-color="white"&ndash;&gt;-->
            <!--                  &lt;!&ndash;                      category-show-all&ndash;&gt;-->
            <!--                  &lt;!&ndash;                      :event-more="more"&ndash;&gt;-->
            <!--                  &lt;!&ndash;                      :from-date="new Date(2018, 0, 1)"&ndash;&gt;-->
            <!--                  &lt;!&ndash;                  />&ndash;&gt;-->
            <!--                  <v-calendar-->
            <!--                      ref="calendar"-->
            <!--                      v-model="focus"-->
            <!--                      :type="ctype"-->
            <!--                      :event-overlap-mode="mode"-->
            <!--                      event-text-color="white"-->
            <!--                      category-show-all-->
            <!--                      @click:date="viewDay"-->
            <!--                      :event-more="more"-->

            <!--                  >-->
            <!--                    <template v-slot:day="{ past, date }">-->
            <!--                      <v-row v-if="checkDate(date)"-->
            <!--                             class="fill-height"-->
            <!--                             align="center"-->
            <!--                             justify="center"-->
            <!--                      >-->

            <!--                        <template>-->

            <!--                          <v-sheet-->
            <!--                              @click="getDay(date)"-->
            <!--                              class='pointer rounded-circle calendar-dot'-->
            <!--                              color="red"-->
            <!--                              width="10"-->
            <!--                              height="10"-->
            <!--                              tile-->
            <!--                          >-->

            <!--                          </v-sheet>-->
            <!--                        </template>-->

            <!--                      </v-row>-->
            <!--                    </template>-->

            <!--                  </v-calendar>-->
            <!--                  <v-menu-->
            <!--                      v-model="selectedOpen"-->
            <!--                      :close-on-content-click="false"-->
            <!--                      :activator="selectedElement"-->
            <!--                      offset-x-->
            <!--                  >-->
            <!--                    <v-card-->
            <!--                        color="grey lighten-4"-->
            <!--                        min-width="350px"-->
            <!--                        flat-->
            <!--                    >-->
            <!--                      <v-toolbar-->
            <!--                          :color="selectedEvent.color"-->
            <!--                          dark-->
            <!--                      >-->

            <!--                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>-->
            <!--                        <v-spacer></v-spacer>-->

            <!--                      </v-toolbar>-->
            <!--                      <v-card-text>-->
            <!--                        <h5>{{ selectedEvent.type }}</h5>-->
            <!--                        <span v-html="selectedEvent.content"></span>-->
            <!--                      </v-card-text>-->
            <!--                      <v-card-actions>-->
            <!--                        <v-btn-->
            <!--                            text-->
            <!--                            color="secondary"-->
            <!--                            @click="selectedOpen = false"-->
            <!--                        >-->
            <!--                          Cancel-->
            <!--                        </v-btn>-->
            <!--                      </v-card-actions>-->
            <!--                    </v-card>-->
            <!--                  </v-menu>-->
            <!--                </v-sheet>-->
            <!--              </div>-->
          </div>

        </div>
          </div>
         </div>
    <!--            <create-and-update ref="room" @refresh_room="getRooms"></create-and-update>-->
      </div>
    </div>


  </v-app>
</template>
<script>
import ProgramService from "@/core/services/level/program/ProgramService";
import {SiteSettingMixin} from "@/mixins/SiteSettingMixin";
import draggable from 'vuedraggable'
import OnlineClassRoomsService from "@/core/services/online-class-rooms/OnlineClassRoomsService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import OnlineClassRoomList from "@/view/component/online-class-room/OnlineClassRoomList";
import RecordedOnlineClassRoomList from "@/view/component/online-class-room/RecordedOnlineClassRoomList";

const programService = new ProgramService();
const onlineCLassRoom = new OnlineClassRoomsService();
const gradeLevelService = new GradeLevelService();

export default {
  name: "rooms",
  mixins: [SiteSettingMixin],
  display: "Table",
  order: 8,
  components: {
    draggable, OnlineClassRoomList, RecordedOnlineClassRoomList
  },
  data() {
    return {
      active_statuses: [
        {title: 'Active', value: "active"},
        {title: 'Inactive', value: "in_active"}
      ],
      days_rooms: [],
      programs: [],
      rooms: [],
      dates: [],
      grades: [],
      recorded_rooms: [],
      recorded_page: null,
      recorded_total: null,
      recorded_perPage: null,
      snackbar: false,
      menu: false,
      text: "My timeout is set to 2000.",
      timeout: 2000,
      page: null,
      perPage: null,
      total: null,
      search: {
        active: "active",
        is_parent: "null",
        dates:[],
      },
      dragging: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      more: false,
      mode: 'column',
      focus: '',
      ctype: 'month',
      types: ['month', 'week', 'day', '4day'],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      events: [],
    };
  },

  mounted() {
    this.getSetting();
    this.getPrograms();
    if (this.setDefaultDates()) {
      this.searchResult();
    }

    // this.getAvailableDates();

  },
  computed: {
    items() {
      let items = [];

      // if (this.rooms && this.rooms.length > 0) {
      for (let i in this.rooms) {

        let startDate = new Date(this.rooms[i]).toISOString().substring(0, 10);
        let data = {
          dot: this.colors[this.rnd(0, this.colors.length - 1)],
          dates: startDate
        }
        items.push(data)
      }
      // }
      return items;
    },
  },
  methods: {
    setToday() {
      this.focus = ''
    },
    viewDay({date}) {
      this.search.date = date;
      this.getRooms();
    },
    getDay(date) {
      this.search.date = date;
      this.getRooms();
    },
    checkDate(date) {
      if (this.rooms && this.rooms.length && this.rooms.includes(date)) {
        return true;
      } else
        return false;
    },
    searchResult() {
      this.search.dates = []
      if (this.dates && this.dates.length > 0) {
        this.search.dates = this.dates
      }
      this.getRooms()
      this.getRecordedRooms()
    },
    getRooms() {
      this.search.search_type = null
      onlineCLassRoom
          .paginateOnlineClassRoom(this.search, this.page)
          .then(response => {
            this.days_rooms = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch(error => {
          });
    }, getRecordedRooms() {
      this.search.search_type = "recorded"
      // alert(this.search.search_type)
      onlineCLassRoom
          .paginateOnlineClassRoom(this.search, this.recorded_page)
          .then(response => {
            this.recorded_rooms = response.data.data
            this.recorded_page = response.data.meta.current_page;
            this.recorded_total = response.data.meta.total;
            this.recorded_perPage = response.data.meta.per_page;
          })
          .catch(error => {
          });
    },
    getAvailableDates() {
      onlineCLassRoom.getAllActiveDates(this.search).then(response => {
        this.rooms = response.data
        if (this.rooms && this.rooms.length) {
          this.rooms = this.rooms.map(date => {
            return this.$moment(date).format('YYYY-MM-DD')
          })
        }
      })
    },
    onCopy: function (e) {
      this.text = "You just copied  ";
      this.snackbar = true;
    },
    onError: function (e) {
      this.text = "Failed to copy ";
      this.snackbar = true;
    },
    addRooms() {
      this.$tabs.close().then(close => {
        this.$router.push({name: 'online-class-rooms-create'})
      })
    },
    editRooms(id) {
      this.$router.push({name: 'online-class-rooms-update', params: {room_id: id}})
    },
    manageCategory() {
      this.$refs['manageCategory'].showModal();
    },
    getPrograms() {
      programService.all().then(response => {
        this.programs = response.data;
      });
    },
    getGrades(program_id) {
      this.loading = true;
      gradeLevelService.getByProgram(program_id).then(response => {
        this.grades = response.data;

      }).finally(() => {
        this.loading = false;
      });
    },
    deleteRooms(id) {
      this.$confirm({
        message: `Are you sure you want to delete this classroom permanently?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            onlineCLassRoom
                .delete(id)
                .then(response => {
                  this.$snotify.success("Information updated")
                  this.getRooms();
                  this.getAvailableDates();
                })
                .catch(error => {
                });
          }
        }
      });
    },
    detail(id) {
      this.$router.push({name: "room-photo", params: {id: id}});
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    resetSearch() {
      this.search = {
        title: null,
        program_id: null,
        active: "active",
      }
      if (this.setDefaultDates()) {
        this.searchResult()
      }
    },
    setDefaultDates() {
      this.dates = [];
      let startDate = this.$moment(new Date).format('YYYY-MM-DD');
      let endDate = this.$moment(new Date).add(7, 'days').format('YYYY-MM-DD');
      this.dates.push(startDate)
      this.dates.push(endDate)
      return true
    }
  }
}
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
</style>
